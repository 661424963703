import React from "react";
import styles from "./App.module.css";

function NotFound() {
  return (
    <main className={styles.main}>
      <h1>Not Found</h1>
    </main>
  );
}

export default NotFound;
